import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from '../Carousel';

import { benefitsList } from './_data';
import CloseIcon from './close_icon.png';

const benefits = {
  carouselSlides: benefitsList,
  contentful_id: null,
  copy: {
    copy:
      'As well as being one of the Best Workplaces in the UK, Cherry also offers a wide range of benefits, including:',
  },
  heading: 'Benefits',
};

export default function Benefits() {
  const [selectedCard, setSelectedCard] = useState(null);

  const closeCard = () => {
    setSelectedCard(null);
  };

  return (
    <Wrapper>
      <FullScreenCard card={selectedCard} close={() => closeCard()} />
      <Carousel
        data={benefits}
        viewName="benefits"
        setSelectedCard={setSelectedCard}
      />
    </Wrapper>
  );
}

const FullScreenCard = ({ card, close }) => {
  return card ? (
    <FullScreenWrapper>
      <button aria-label="Close card description" onClick={close}>
        <img src={CloseIcon} alt="Close" />
      </button>
      <p>{card.back && card.back.text}</p>
    </FullScreenWrapper>
  ) : null;
};

const Wrapper = styled.div`
  background-color: #f4f4f4;
  min-height: 800px;
`;

const FullScreenWrapper = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #91004b;
  height: 100vh;

  button {
    position: absolute;
    right: 28px;
    top: 28px;
    background: none;
    border: none;
    padding: 0px;
    cursor: pointer;
    background: #ffffff;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;

    img {
      width: 10px;
      height: 10px;
      margin: 0 auto;
    }
  }

  p {
    padding: 148px 24px 0px;
    color: #ffffff;
    line-height: 24px;
  }

  @media screen and (min-width: 640px) {
    display: none;
  }
`;
