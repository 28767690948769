import React from 'react';
import styled from 'styled-components';
import Carousel from '../Carousel';

import Wellbeing2022 from './2022_wellbeing.png';
import Workplaces2022 from './2022_best_workplaces.png';
import WorkplacesWomen2022 from './2022_best_workplaces_women.png';

const slideOne = {
  copy: {
    copy: 'Our facilities contribute to a good work environment',
  },
  heading: '96<span>%</span>',
};
const slideTwo = {
  copy: {
    copy: 'Management is ethical and honest in its business practices',
  },
  heading: '94<span>%</span>',
};
const slideThree = {
  copy: { copy: 'People care about each other here' },
  heading: '97<span>%</span>',
};
const slideFour = {
  copy: {
    copy: 'Management is competent at running the business',
  },
  heading: '94<span>%</span>',
};
const slideFive = {
  copy: {
    copy: 'I am treated as a full member here regardless of my position',
  },
  heading: '93<span>%</span>',
};
const slideSix = {
  copy: {
    copy: 'When you join the company, you are made to feel welcome',
  },
  heading: '98<span>%</span>',
};

const awards = {
  carouselSlides: [
    slideOne,
    slideTwo,
    slideThree,
    slideFour,
    slideFive,
    slideSix,
  ],
  contentful_id: null,
  copy: {
    copy: `Cherry is delighted to be considered one of the Best Workplaces in the UK, overall. As well as one of the Best Workplaces both for Wellbeing and for Women.<br/><br/> Here's what our staff had to say about Cherry in the recently conducted Best Workplaces survey:`,
    images: [
      { url: Workplaces2022, alt: '' },
      { url: Wellbeing2022, alt: '' },
      { url: WorkplacesWomen2022, alt: '' },
    ],
  },
  heading: 'Workplace awards',
};

export default function WorkplaceAwards() {
  return (
    <Wrapper>
      <Carousel data={awards} isTablet={false} width={282} viewName="awards" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f4f4f4;
`;
