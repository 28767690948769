import EmployeeAssistanceImg from './employee-assistance.png';
import AnnualLeaveImg from './annual-leave.png';
import HybridWorkingImg from './hybrid-working.png';
import VolunteeringImg from './volunteering.png';
import SummerFridayImg from './summer-friday.png';
import PrivateInsuranceImg from './private-insurance.png';
import TravelInsuranceImg from './travel-insurance.png';
import PensionImg from './pension.png';
import GymImg from './gym.png';
import EyeTestsImg from './eye-tests.png';
import ReferralBonusImg from './referral-bonus.png';
import CycleToWorkImg from './cycle-to-work.png';
import SeasonTicketImg from './season-ticket.png';
import SocialsImg from './socials.png';
import WorkFromAnywhereImg from './cup.png';
import FlexibleWorkingImg from './clock.png';

const employeeAssistance = {
  front: {
    image: { src: EmployeeAssistanceImg, alt: '' },
    heading: 'Employee Assistance Programme',
  },
  back: {
    text:
      "Access any mental health assistance you may need, confidentially and free of charge. Whether it's a chat with a counsellor or online tools and programmes, we have a range of options to choose from.",
  },
};
const annualLeave = {
  front: {
    image: { src: AnnualLeaveImg, alt: '' },
    heading: 'Time off',
  },
  back: {
    text:
      'In addition to bank holidays and volunteering days (more on that later), all Cherry employees get 25 days of holiday a year. For the first years that you’re with us, this will increase. Cherry also offers an extra 5 days unpaid leave.',
  },
};

const hybridWorking = {
  front: {
    image: { src: HybridWorkingImg, alt: '' },
    heading: 'Hybrid working',
  },
  back: {
    text:
      'Being in an office Monday to Friday is so 2019. At Cherry, you can decide to work from home, or from our beautiful London office, overlooking the Thames.',
  },
};

const workFromAnywhere = {
  front: {
    image: { src: WorkFromAnywhereImg, alt: '' },
    heading: 'Work from anywhere',
  },
  back: {
    text:
      'Enjoy the freedom of remote working from anywhere around the world for 4 weeks a year.',
  },
};

const flexibleWorking = {
  front: {
    image: { src: FlexibleWorkingImg, alt: '' },
    heading: 'Flexible working',
  },
  back: {
    text: `Many of our staff work part time or enjoy flexible start and finish times. We can't guarantee the exact hours you want but are always open to suggestions.`,
  },
};

const volunteeringDays = {
  front: {
    image: { src: VolunteeringImg, alt: '' },
    heading: 'Become a community superhero ',
  },
  back: {
    text:
      'Twice a year swap your laptop for an apron or gardening gloves and get involved by taking part in our paid volunteering days. You can organise and attend your own charitable cause or join fellow employees on volunteering days organised by our CSR (Corporate Social Responsibility) Committee.',
  },
};
const summerFridays = {
  front: {
    image: { src: SummerFridayImg, alt: '' },
    heading: 'Summer Fridays',
  },
  back: {
    text:
      'More fun in the sun. Get a head start on the weekend. During the summer, enjoy an early finish every Friday.',
  },
};
const privateInsurance = {
  front: {
    image: { src: PrivateInsuranceImg, alt: '' },
    heading: 'Private Medical insurance',
  },
  back: {
    text:
      "Avoid long NHS waiting lists with access to Aviva Private Medical Insurance. From mental health to dental care, we've got you covered!",
  },
};
const travelInsurance = {
  front: {
    image: { src: TravelInsuranceImg, alt: '' },
    heading: 'Travel insurance',
  },
  back: {
    text:
      'Enjoy your holidays in peace with automatic cover on our free travel insurance. This applies to both business and personal travel.',
  },
};
const pension = {
  front: {
    image: { src: PensionImg, alt: '' },
    heading: 'Pension',
  },
  back: {
    text:
      'National Insurance and tax deductions have gone up recently, but we are mitigating the impact on our people through our pension scheme.',
  },
};
const gym = {
  front: {
    image: { src: GymImg, alt: '' },
    heading: 'Gym',
  },
  back: {
    text:
      'Get summer body ready all year round with discounts at Fitness First.',
  },
};
const eyeTests = {
  front: {
    image: { src: EyeTestsImg, alt: '' },
    heading: 'Eye tests',
  },
  back: {
    text:
      "As long as you're with us, you'll never have to pay for an annual eye test again!",
  },
};
const referralBonus = {
  front: {
    image: { src: ReferralBonusImg, alt: '' },
    heading: 'Referral Bonus',
  },
  back: {
    text:
      'Enjoy extra cash for referring your equally talented friends and old work collegues.',
  },
};

const cycleToWork = {
  front: {
    image: { src: CycleToWorkImg, alt: '' },
    heading: 'Cycle to work',
  },
  back: {
    text:
      'We are part of the Cycle to Work Scheme, allowing you to enjoy a tax-deductible bicycle through us.',
  },
};

const seasonTicketLoan = {
  front: {
    image: { src: SeasonTicketImg, alt: '' },
    heading: 'Season ticket loan',
  },
  back: {
    text:
      'For long-distance commuters, we know travel is expensive! Cherry offers an interest-free travel loan for people commuting from zone 3 and beyond!',
  },
};
const socials = {
  front: {
    image: { src: SocialsImg, alt: '' },
    heading: 'Socials',
  },
  back: {
    text:
      'Enjoy company-wide quarterly events, as well as monthly social events like wine and cheese nights, pub quizzes etc. We encourage (and fund) team socials, as well as providing drinks in the office for anyone who is around every Thursday or Friday!',
  },
};

export const benefitsList = [
  employeeAssistance,
  annualLeave,
  hybridWorking,
  workFromAnywhere,
  flexibleWorking,
  volunteeringDays,
  summerFridays,
  privateInsurance,
  travelInsurance,
  pension,
  gym,
  eyeTests,
  cycleToWork,
  seasonTicketLoan,
  socials,
];
