import React, { useState } from 'react';
import Benefits from '../../components/Benefits';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer/index.js';
import ImageText from '../../components/ImageText';
import JobSlider from '../../components/JobSlider';
import Layout from '../../components/Layout';
import Nav from '../../components/Nav';
import Scroller, { Section } from '../../components/Scroller';
import SEO from '../../components/SEO';
import Sparks from '../../components/Sparks';
import WorkplaceAwards from '../../components/WorkplaceAwards';
import CSRReel from '../../components/CSRReel';

import medium from './grow-with-us-ipad.png';
import large from './grow-with-us.png';
import Menu from '../../components/Menu';
import Navigation from '../../components/Navigation';

const GrowWithUs = () => {
  const images = [
    { media: '(max-width: 1023px)', srcSet: medium },
    { media: '(min-width: 1024px)', srcSet: large },
  ];
  const heading = 'Grow with us';
  const text = [
    'The success of Cherry continues to grow. Through extraordinary talent, adventurous clients and creative opportunities, Cherry has blossomed into a multi-award-winning agency.',
    "We believe the best work comes from a happy working environment. So, we've packed our gorgeous London office with the latest facilities, the greatest opportunities, and the friendliest colleagues.",
    'That is why we offer all Cherry employees every opportunity they need to grow. As our talent blooms, so do we.',
  ];

  return <ImageText images={images} heading={heading} text={text} />;
};

const CultureHero = () => {
  return <Hero isCulturePage={true} />;
};

const sections = [
  { component: CultureHero, id: '' },
  { component: WorkplaceAwards, id: 'awards' },
  { component: GrowWithUs, id: 'grow' },
  { component: CSRReel, id: 'csr' },
  { component: Benefits, id: 'benefits' },
  { component: Sparks, id: 'sparks' },
  { component: JobSlider, id: 'careers' },
];

export default function Culture() {
  const [showContactPage, setShowContactPage] = useState(false);
  const [showNav, setShowNav] = useState(false);

  return (
    <Layout>
      <SEO
        GAEnabled={false}
        title="Award-winning global healthcare creative agency"
        keywords={[`cherry`, `digital`, `advertising`, `healthcare`]}
      />
      <Scroller>
        <Navigation isCulturePage={true} isSecondary={true} />

        {sections.map(({ component, id }, index) => {
          return (
            <Section key={`${id}-${index}`} Component={component} id={id} />
          );
        })}
        <Footer
        // openCookieSettings={() => {
        //   setModal('cookieSettings');
        // }}
        // openPrivacyPolicy={() => {
        //   setModal('privacyPolicy');
        // }}
        // closeModal={closeModal}
        />
        {/* {modal === 'cookieSettings' && (
          <CookieSettings
            closeModal={closeModal}
            setCookies={setCookies}
            cookieConsent={cookieConsent}
            setModal={setModal}
            openCookieSettings={() => {
              setModal('cookieSettings');
            }}
            openPrivacyPolicy={() => {
              setModal('privacyPolicy');
            }}
          />
        )}
        {modal === 'privacyPolicy' && (
          <PrivacyPolicy
            setCookieConsent={setCookies}
            setCookiesRead={setCookiesRead}
            setModal={setModal}
            closeModal={closeModal}
            openCookieSettings={() => {
              setModal('cookieSettings');
            }}
            openPrivacyPolicy={() => {
              setModal('privacyPolicy');
            }}
          />
        )} */}
      </Scroller>
    </Layout>
  );
}
