import React from 'react';
import styled from 'styled-components';

export default function ImageText({
  image = { src: '', alt: '' },
  images = [],
  heading = '',
  text = [],
}) {
  return (
    <Wrapper>
      {images.length > 0 ? (
        <picture>
          {images.map((el, index) => {
            const { media, srcSet } = el;

            return <source key={index} media={media} srcSet={srcSet} />;
          })}
          <Image src={images[0].srcSet} alt="" />
        </picture>
      ) : (
        <img src={image.src} alt={image.alt} />
      )}
      <TextContent>
        <h4>{heading}</h4>
        {text.map((el, index) => {
          return <p key={index}>{el}</p>;
        })}
      </TextContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: #ffffff;

  @media screen and (min-width: 1024px) {
    flex-direction: row;

    > * {
      flex: 1;
    }
  }
`;
const Image = styled.img`
  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

const TextContent = styled.div`
  margin: 64px 18px 32px;

  h4 {
    color: #91004b;
    font-size: 32px;
    margin-bottom: 24px;
    max-width: 544px;
    font-weight: 100;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3d3f46;
    max-width: 532px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: 580px) {
    margin-inline: auto;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 64px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4,
    p {
      max-width: 463px;
    }
    h4 {
      font-size: 48px;
    }
  }
  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    p {
      font-size: 1.6em;
      line-height: 1.5;
      max-width: unset;
      margin-right: 64px;
    }
  }
`;
