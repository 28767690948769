import React from 'react';
import styled from 'styled-components';
import Carousel from '../Carousel';
import { csrReel } from '../../data/culturePageData';

export default function CSRReel() {
  return (
    <Wrapper>
      <Carousel data={csrReel} viewName="csrReel" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #ffffff;
`;
